<template>
  <div>
    <b-card no-body>
      <b-card title="Lens Package Price" header-tag="header">
        <template v-slot:header>
          <product-form-nav
            link="lens"
            :productDetails="productDetails"
            :productId="id"
          ></product-form-nav>
        </template>
		<form class="form" novalidate="novalidate" id="lens_price">
        <b-card-text> 
			<b-row>
				<b-col lg="3" md="6">
					<b-form-group>
						<label>Price Group</label>
						<b-form-select v-model="selection" :options="packageGroups"></b-form-select>
					</b-form-group>
				</b-col>
				
			</b-row>
			<div v-if="form.lensePackageGroupID != null">
			<b-tabs content-class="mt-3" fill>
				<b-tab v-for="(pack,i) in packages" :key="pack.id" :title="pack.name" :active="i==0?true:false">
					<b-form-input
							v-model="form.packageInfo[pack.id].packageId"
							hidden
					></b-form-input>
					<div v-for="lens in lensTypes" :key="lens.id">
						<b-row class="pt-5" v-if="lens.children == undefined">
						<b-col lg="3" md="6" >
							<b-form-group>
							<label>{{lens.name}}</label>
							<b-form-input
								v-model="form.packageInfo[pack.id].typeInfo[lens.id].price"
								placeholder="0.00"
								type="number"
								min="0"
							></b-form-input>
							</b-form-group>
						</b-col>
						</b-row>
						<div v-else>
						<h3 class="pb-4">{{lens.name}}</h3>
						<b-card class="bg-light mb-5">
						<div  v-for="coating in lens.children" :key="coating.name">
						<h6 class="pb-4">{{coating.name}}</h6>
						<b-row class="pb-5">

							<b-col lg="3" md="4" sm="6" v-for="color in coating.children" :key="color.name">
							<div class="border bg-white">
								<div class="row no-gutters align-items-center">
								<div class="border-right col pl-1">
									<img src="/img/color-thump.jpg" width="25" />
									<span class="font-weight-bolder"> {{color.name}}</span>
								</div>
								<div class="border-right col">
									<b-form-input
									v-model="form.packageInfo[pack.id].typeInfo[color.id].price"
									type="number"
									min="0"
									placeholder="0.00"
									class="border-0"
									size="sm"
									></b-form-input>
								</div>
								</div>
							</div>
							</b-col>
						
						</b-row>
						</div>
						</b-card>
						</div>
					</div>
				</b-tab>
			</b-tabs>
			<b-row>
				<b-col class="text-right">
					<button ref="submit_price" class="btn btn-sm btn-outline-primary font-size-h6">
							<i class="las la-save"></i> Save
					</button>
				</b-col>
			</b-row>
			</div>
		</b-card-text>
		</form>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import ProductFormNav from "./components/ProductFormNav.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
//api services
import ProductService from "@/core/services/api/product/product";
import LensPackageService from "@/core/services/api/lensPackage";
export default {
  components: {
    ProductFormNav,
  },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
	  packageGroups:[],
	  selection:null,
	  packages: [],
	  lensTypes: [],
	  productDetails:{},
	  packagePriceDetails:null,
	  flag:false
    };
  },
  	created (){
		this.getPriceGroups();
		this.loadPackages();
		this.loadLensTypes();
		this.getProductById();
	},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Products",
        route: "/products",
      },
      {
        title: "Manage Product : Lens Package",
      },
    ]);
	
	const signin_form = KTUtil.getById("lens_price");
	this.fv = formValidation(signin_form, {
		fields: {
			
		},
		plugins: {
			trigger: new Trigger(),
			submitButton: new SubmitButton(),
			bootstrap: new Bootstrap(),
		},
	});
	this.fv.on("core.form.valid", () => {
		// set spinner to submit button
		const submitButton = this.$refs["submit_price"];
		submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
		this.updateProduct(function(){
			submitButton.classList.remove(
				"spinner",
				"spinner-light",
				"spinner-right",
				"disabled"
			);
		});
	});
  },
	computed: {
		form:function() {
			var id = this.selection;
			var productDetails = this.productDetails;
			var form = {
				lensePackageGroupID: null,
				packageInfo:{}
			};
			var packagePriceDetails = this.packagePriceDetails;

			if(id && packagePriceDetails){
				var priceDetails = {};
				if(productDetails.packageInfo != undefined && id == productDetails.lensePackageGroupID){
					priceDetails = productDetails.packageInfo;
				}else{
					priceDetails = packagePriceDetails.packageGroupPricing;
				}
				form = {
					lensePackageGroupID: id,
					packageInfo:{}
				}
				for(const pack of this.packages) {
					var packInfo = {
						packageId:pack.id,
						typeInfo:{}
					}
					for(const lens of this.lensTypes){
						if(lens.children != undefined){
							for(const subType of lens.children){
								if(subType.children != undefined){
									subType.children.map(element=>{
										var price ="0.00";
										if(id){
											var item = priceDetails.find(el => {
												return el.package_id == pack.id && el.type_id == element.id
											});
											if(item != undefined)
												price = item.price;
										}
										packInfo.typeInfo[element.id] = {
											typeID:element.id,
											price: price
										}
									})
								}
							}
						}else{
							var price ="0.00";
							if(id){
								var item = priceDetails.find(el => {
									return el.package_id == pack.id && el.type_id == lens.id
								});
								if(item != undefined){
									price = item.price;
								}
							}
							packInfo.typeInfo[lens.id] = {
								typeID: lens.id,
								price: price
							}
						}
					}
					form.packageInfo[pack.id] = packInfo
				}
			}

			return form;
		}
	},
	watch:{
		selection: function(val) {
            this.getLensPriceGroupId();
        }
	},
  	methods: {
		async getPriceGroups() {
			var response = await LensPackageService.getLensPriceGroups();
			this.packageGroups.push ({
				value:null,
				text:"Please select a price group"
			});
			response.map((e)=>{
				this.packageGroups.push ({
					value:e.id,
					text:e.name
				})
			});
		},
		async loadPackages() {
			var response = await LensPackageService.getLensPackages();
				this.packages = response;
		}, 
		async loadLensTypes() {
			var response = await LensPackageService.getLensTypes();
				this.lensTypes = response;
		},
		async getProductById() {
			var response = await ProductService.getProductById(this.id);
			this.productDetails = response;
			if(response.lensePackageGroupID != undefined)
				this.selection = response.lensePackageGroupID;
		},
		async getLensPriceGroupId() {
			if(this.selection){
				var response = await LensPackageService.getLensPriceGroupById(this.selection);
				this.packagePriceDetails = response;
			}
		},
		updateProduct(callback) {
			var vm = this;
			ProductService.updateProduct(vm.id,vm.form).then(function (response) {
					if(vm.productDetails.varient == 1)
						vm.$router.push({ name: "product-form-options", param: { id: vm.id } })
					else
						vm.$router.push({ name: "product-form-image-upload", param: { id: vm.id } })
			}).catch(function (error){
				callback();
			});
		},
	},
};
</script>

<style></style>
